@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.app-list {
  display: inline-block;
  width: 40px;
  position: relative;

  .icon {
    display: inline-block;
    width: 24px;
    height: 24px;

    &:hover {
      cursor: pointer;
    }
  }

  .footer-wechat-code {
    position: absolute;
    width: 128px;
    height: 168px;
    background: #FFF;
    box-shadow: 0 12px 80px rgb(34 39 56 / 6%);
    border-radius: 12px;
    top: -185px;
    left: -50px;

    img {
      margin: 16px 16px 8px;
    }

    span {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #232426;
    }
  }
}