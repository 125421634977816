@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.business {
  height: 328px;
  position: relative;
  z-index: 10;

  .container {
    width: 1200px;
    height: 428px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FF4E33;
    border-radius: 48px;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      line-height: 72px;
      color: #FFF;
      display: flex;
      align-items: center;
      text-align: center;

      :global {
        .en {
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 56px;
          text-align: center;
        }

        .zh {
          font-weight: 500;

          img {
            display: inline-block;
            height: 76px;
            margin-top: -10px;
          }
        }
      }

      img {
        width: 360px;
        height: 60px;
        margin-bottom: 0 !important;
        box-sizing: border-box;
        vertical-align: middle;
        border-radius: 0 !important;
      }
    }

    .explore {
      width: 196px;
      margin-left: 30px;
      height: 56px;
      background: #FFF;
      border: 1px solid #FFF;
      transform: skew(-15deg);
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      margin-top: 48px;
      color: #FF4E33;
      cursor: pointer;

      &:hover {
        color: #FF7B66;
      }

      &:active {
        color: #E6462E;
      }

      span {
        display: inline-block;
        text-decoration: none;
        transform: skew(15deg);
      }
    }
  }

  :global .lazy-img {
    &::before {
      position: absolute;
      content: url('#{$STATIC_ASSETS_URL}/overseacdn/homepage/static/images/home/business/background.png');
    }
  }
}

.business-others {
  background-color: #F7F6F5;
  margin-bottom: -120px;

  .container {
    height: 360px;

    .title {
      font-size: 40px;
      line-height: 35px;

      img {
        width: 244px;
        height: 38px;
        margin-top: 0;
      }

      :global {
        .en {
          font-style: normal;
          font-weight: 700;
          font-size: 42px;
          text-align: center;
        }
      }
    }
  }
}

.business-services {
  background-color: #FFF;
  height: 280px;
}