@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.header {
  height: 70px;
  min-width: 1200px;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: transparent;
  z-index: 20;
  transition: all .3s ease-in-out;
  margin: 0 auto;
}

.menu-item {
  position: relative;
  color: #000;
  border-radius: 4px;
  background: transparent;
  z-index: 10;
  margin-left: 10px;

  .menu-item-select-panel {
    position: absolute;
    top: 35px;
    left: -10px;
    border-radius: 6px;
    box-shadow: 8px 8px 16px rgb(0 0 0 / 3%);
    overflow-y: auto;

    .menu-item-select-item {
      display: block;
      text-align: center;
      color: #000;
      background-color: #fff;
      transition: background-color 0.3s;

      &:hover {
        color: #FF4E33;
        background-color: #FFEEEB;
      }
    }
  }
}

.home-style {
  li,
  .menu-item {
    span {
      color: #FFF;
    }
  }

  button {
    background-color: #fff !important;
  }
}

.scroll {
  background-color: #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: 16px;

  .logo {
    margin: 15px 310px 0 0;
    min-width: 207px;
  }

  .banner {
    min-width: 683px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    margin: 15px 360px 0 0;

    &__item {
      span {
        &:hover {
          color: $primary-color;
        }
      }

      &__home {
        span {
          &:hover {
            color: #FFF;
            font-weight: 500;
          }
        }
      }
    }

    ul {
      flex: 4;
      list-style: none;
      display: flex;
      margin: 0;

      li {
        flex: 1;
        min-width: 96px;
        text-align: center;

        a {
          display: inline-block;
          color: #000;
          text-decoration: none;
          font-weight: 400;
          font-size: 100%;
          line-height: 36px;
          text-align: center;
        }

        .active {
          color: $primary-color;
        }
      }
    }

    .locales {
      flex: 1px;
      min-width: 94px;
      text-align: center;
      line-height: 36px;
      vertical-align: middle;
      cursor: pointer;
    }

    .login {
      width: 105px;
      margin-left: 30px;
      font-size: 16px;
      height: 36px;
      background: #FFF;
      transform: skew(-20deg);
      border-radius: 5px;
      border: 1px solid $primary-color;
      color: $primary-color;
      cursor: pointer;
      background-color: transparent;

      &:hover {
        color: #FF7B66;
        border-color: #FF7B66;
      }

      &:active {
        color: #E6462E;
        border-color: #E6462E;
      }

      span {
        display: inline-block;
        text-decoration: none;
        transform: skew(20deg);
      }
    }
  }
}