@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.footer {
  background-color: #f7f6f5;

  .policies {
    bottom: 0;
    height: 73.5px;
    max-width: 1200px;
    margin: 60px auto 0;
    line-height: 73.5px;
    border-top: 1px solid rgba($color: #000, $alpha: 20%);
    display: flex;
    justify-content: center;

    ul {
      li {
        line-height: 14px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;

        span {
          display: inline-block;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: rgba($color: #232426, $alpha: 60%);
          cursor: pointer;

          &:hover {
            color: #ff4e33;
          }
        }
      }

      .border {
        margin: 0 1em;
        padding: 0 1em;
        border-left: 1px solid rgba($color: #232426, $alpha: 60%);
        border-right: 1px solid rgba($color: #232426, $alpha: 60%);
      }

      .record a {
        display: inline-block;
        padding-left: 1rem;
        margin-left: 1rem;
        font-size: 14px;
        color: rgb(35 36 38 / 60%);
        text-decoration: none;
        border-left: 1px solid rgb(35 36 38 / 60%);

        &:hover {
          color: #ff4e33;
        }
      }

      .netease__num {
        padding-left: 1rem;
        margin-left: 1rem;
        font-size: 14px;
        color: rgb(35 36 38 / 60%);
        border-left: 1px solid rgb(35 36 38 / 60%);
      }
    }
  }

  .content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;

    .left {
      width: 744px;
      margin-top: 360px;

      .logo {
        margin-bottom: 20px;
      }

      .logotext {
        span {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #232426;
          mix-blend-mode: normal;
        }
      }

      .applist {
        margin-top: 82px;
        height: 24px;
        width: 200px;
      }
    }

    .right {
      margin-top: 360px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;

      .nav {
        width: 98px;

        &:first-child {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          color: #232426;
        }

        ul {
          list-style: none;
          width: 94px;

          li {
            margin-top: 28px;

            a {
              text-decoration: none;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 100%;
              color: #232426;

              &:hover {
                color: #ff4e33;
              }
            }
          }
        }
      }

      .contact {
        margin-left: 60px;
        width: 310px;

        span {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          color: #232426;
        }

        ul {
          list-style: none;

          li {
            margin-top: 28px;
            line-height: 18px;

            span {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 100%;
              color: #232426;
            }
          }
        }
      }
    }
  }

  .netease__icon {
    max-width: 1200px;
    margin: 20px auto -40px;

    svg {
      margin-right: 5px;
    }

    div {
      font-weight: 400;
      font-size: 14px;
      color: rgba($color: #232426, $alpha: 60%);
    }
  }
}
