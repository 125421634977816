@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.highlight {
  color: $primary-color;
  font-family: FZLanTingHeiS-B-GB, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.quote {
  &::before,
  &::after {
    content: '"';
  }
}

.newline {
  display: block;
}

.p {
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  text-align: justify;
  color: #393B40;
}

.title {
  margin: 48px 0 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  color: #232426;
}

.strong {
  font-weight: bold;
}

.img {
  width: 960px;
  margin: 0 auto 24px;
  border-radius: 30px;
}

.img-title {
  margin: 0 auto 48px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: #232426;
  opacity: 0.7;
}

.li {
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  text-align: justify;
  color: #393B40;
}

.center {
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  text-align: center;
  color: #393B40;
}