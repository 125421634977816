@import "@/styles/variables.scss";$STATIC_ASSETS_URL:'https://overseacdn.ydstatic.com';
.container {
  position: fixed;
  left: calc(50vw - 600px);
  bottom: 10px;
  display: flex;
  align-items: center;
  padding: 14px 24px;
  background-color: #fff;
  box-shadow: 0 2px 40px rgb(6 13 43 / 12%);
  border-radius: 8px;
  z-index: 99;

  a {
    color: $primary-color;
    text-decoration: none;
  }
}

.confirm {
  position: relative;
  margin-left: 12px;
  padding: 8px 24px;
  color: $primary-color;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px solid $primary-color;
    border-radius: 8px;
    transform: skewX(-20deg);
    z-index: 0;
  }

  >span {
    position: relative;
    z-index: 1;
  }
}
